import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BackService {
  private backButtonClickSubject = new Subject<boolean>();
  backButtonClick$ = this.backButtonClickSubject.asObservable();

  private showBackButtonSubject = new BehaviorSubject<boolean>(false);
  showBackButton$ = this.showBackButtonSubject.asObservable();

  constructor() {}

  backButtonClick(): void {
    this.backButtonClickSubject.next(true);
  }

  showBackButton(show: boolean): void {
    this.showBackButtonSubject.next(show);
  }
}
